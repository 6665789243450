import { Box, Button, TextField, Typography } from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { useSupabase } from "../../providers/AuthContextProvider";
import { useEffect, useState } from "react";
import { ProtectedAdmin } from "./ProtectedAdmin";
import { useAuth } from "../../providers/AuthContextProvider";
import { Navigate, useLocation } from "react-router-dom";

export const Admin_Panel = () => {
  const [Table, setTable] = useState([]);
  const { user } = useAuth();
  const supabase = useSupabase();
  useEffect(() => {
    const data_get = async () => {
      const { data: _Table } = await supabase
        .from("users")
        .select("id,full_name,phone_number,email");
      setTable(_Table);
    };
    data_get();
  }, []);
  if (ProtectedAdmin(user)) {
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "coachname", headerName: "Coach Name", width: 270 },
      { field: "email", headerName: "E-mail", width: 270 },
      { field: "phonenumber", headerName: "Phone Number", width: 270 },
    ];

    if (Table.length === 0) {
      return (
        <>
          <PageContainer>
            <div>Loading...</div>
          </PageContainer>
        </>
      );
    }

    const rows = [];
    if (Table.length > 0) {
      Table.forEach((i, index) => {
        const js = {
          id: index,
          coachname: i.full_name,
          email: i.email,
          phonenumber: i.phone_number,
        };
        rows.push(js);
      });
    }

    const paginationModel = { page: 0, pageSize: 20 };
    return (
      <>
        <PageContainer>
          <Typography variant="h5">Admin panel</Typography>
          <Paper sx={{ height: 800, width: "100%" }}>
            {Table.length !== 0 ? (
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[20, 25]}
                checkboxSelection
                sx={{ border: 0 }}
              />
            ) : (
              "Kayıtlı Kullanıcı Datası Yoktur."
            )}
          </Paper>
        </PageContainer>
      </>
    );
  }
  return <Navigate to="/" replace />;
};
