export const sportsEmojis = [
  "⚽️",
  "🏀",
  "🏈",
  "⚾️",
  "🎾",
  "🏐",
  "🏉",
  "🎱",
  "🏓",
  "🏸",
  "🏒",
  "🏑",
  "🥍",
  "🏏",
  "⛳️",
  "🏹",
  "🎣",
  "🥊",
  "🥋",
  "⛸",
  "🎿",
  "⛷",
  "🏂",
  "🏋️‍♀️",
  "🤼‍♀️",
  "🤸‍♀️",
  "⛹️‍♀️",
  "🤺",
  "🏌️‍♀️",
  "🏄‍♀️",
  "🚣‍♀️",
  "🏊‍♀️",
  "🤽‍♀️",
  "🚴‍♀️",
  "🚵‍♀️",
  "🏇",
  "🧘‍♀️",
  "🏋️‍♂️",
  "🤼‍♂️",
  "🤸‍♂️",
  "⛹️‍♂️",
  "🤺",
  "🏌️‍♂️",
  "🏄‍♂️",
  "🚣‍♂️",
  "🏊‍♂️",
  "🤽‍♂️",
  "🚴‍♂️",
  "🚵‍♂️",
  "🏋️",
  "🏇",
  "🏆",
];
